import ErrorAlert from "../../ErrorAlert";
import { Loading } from "../../Loading";
import { useUnifiedAuth } from "../../unified-auth";

/**
 * This callback page is just a basic landing page to end up on when a user is redirected from Auth0, if
 * you land here you will just either see an error page or a loading page.
 * @constructor
 */
export default function Callback() {
  const { error, isLoading } = useUnifiedAuth();

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorAlert input={error} />;
  }

  return (
    <>
      <main className="login"></main>
    </>
  );
}

import { useEffect, useRef } from "react";
import { Auth0RedirectLoginOptions, useUnifiedAuth } from "../../unified-auth";

export default function Start() {
  const { loginWithAuth0 } = useUnifiedAuth();
  const isInitialized = useRef(false);

  useEffect(() => {
    (async () => {
      if (isInitialized.current) {
        return;
      }
      isInitialized.current = true;
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get("email") ?? "";
      const screen_hint = urlParams.get("screen_hint") ?? "login";
      const token = urlParams.get("token") ?? undefined;
      const _gl = urlParams.get("_gl") ?? undefined;
      const _utm_source = urlParams.get("utm_source") ?? undefined;
      const _utm_medium = urlParams.get("utm_medium") ?? undefined;
      const _utm_campaign = urlParams.get("utm_campaign") ?? undefined;
      const authorizationParams = stripUndefined({
        _gl: _gl,
        utm_source: _utm_source,
        utm_medium: _utm_medium,
        utm_campaign: _utm_campaign,
      });
      let opts: Auth0RedirectLoginOptions = stripUndefined({
        email: email,
        screen_hint: screen_hint,
        token: token,
      });
      if (Object.keys(authorizationParams).length > 0) {
        opts = {
          additionalAuthorizationParams: authorizationParams,
          ...opts,
        };
      }
      if (isInitialized.current) {
        await loginWithAuth0(opts);
      }
    })();
    return () => {
      isInitialized.current = false;
    };
  }, [loginWithAuth0, isInitialized]);

  return null;
}

const stripUndefined = (params: any) => {
  return Object.keys(params)
    .filter((k) => typeof params[k] !== "undefined")
    .reduce((acc, key) => ({ ...acc, [key]: params[key] }), {});
};

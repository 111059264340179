import React, { useEffect, useState } from "react";
import { Button } from "../../Button";
import BotDetection from "../../components/BotDetection";
import { LOGO_ALT, TWILIO_SENDGRID_LOGO } from "../../constants";
import { signupContext } from "../../context";
import ErrorAlert from "../../ErrorAlert";
import { ExternalLink } from "../../external-navigate";
import { useFormState } from "../../hooks/useFormState";
import { TextInput } from "../../TextInput";
import { useSessionUsername, useUnifiedAuth } from "../../unified-auth";

interface PasswordProps {
  botDetectionEnabled: boolean;
  botDetectionSitekey: string;
  botDetectionToken?: string;
}

export default function Password({
  botDetectionEnabled,
  botDetectionSitekey,
  botDetectionToken = "",
}: PasswordProps) {
  const forgotPasswordHost =
    `${process.env.REACT_APP_MAKO_HOST}/forgot_password` ||
    "https://app.sendgrid.com/forgot_password";
  const [sessionUsername] = useSessionUsername();
  const [username, , changeUsername] = useFormState(sessionUsername);
  const [password, , changePassword] = useFormState("");
  const { error, isLoading, loginWithBasicAuth } = useUnifiedAuth();
  const [errorBanner, setErrorBanner] = useState<string | Error | JSX.Element | undefined>("");
  const [token, setToken] = useState<string>(botDetectionToken);

  useEffect(() => {
    if (error) {
      setErrorBanner(error);
      return;
    }
  }, [error]);

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await loginWithBasicAuth({
      username: sanitizeUsername(username),
      password: password,
      ...(token && { bot_detection_token: token }),
    });
  };

  const style = {
    marginTop: "10pt",
  };

  return (
    <>
      <main className="login">
        {errorBanner && <ErrorAlert input={errorBanner} />}
        <div className="login-container">
          <div data-role="login-modals" className="login-modals">
            <div className="login-modal">
              <div className="login-modal-table">
                <div className="login-modal-table-row">
                  <div className="login-modal-body">
                    <div className="login-logo">
                      <img alt={LOGO_ALT} src={TWILIO_SENDGRID_LOGO} width="246" height="72" />
                    </div>

                    <form data-role="login-form" className="login-form" onSubmit={formSubmit}>
                      <div
                        data-role="login-form-error-container"
                        className="hidden login-form-errors">
                        <div className="row">
                          <div className="col-8 col-start-3">
                            <span
                              className="login-form-error"
                              data-role="login-form-error-text"></span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-8 col-start-3">
                          <TextInput
                            label="Username"
                            id="username"
                            value={username}
                            autoFocus={true}
                            autoComplete={"username"}
                            onChange={changeUsername}
                            containerRole={"usernameContainer"}
                          />
                        </div>
                      </div>
                      <div className="row login-username-options">
                        <div className="col-10">
                          <a
                            className="login-link"
                            target="_blank"
                            rel="noreferrer"
                            href="https://support.sendgrid.com/hc/en-us/requests/new#forgot-username">
                            Forgot your username?
                          </a>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-8 col-start-3">
                          <TextInput
                            label="Password"
                            id="password"
                            value={password}
                            type="password"
                            onChange={changePassword}
                            containerRole={"passwordContainer"}
                          />
                        </div>
                      </div>
                      <div className="row login-password-options">
                        <div className="col-10">
                          <a
                            data-role="forgot-password-link"
                            className="login-link"
                            href={forgotPasswordHost}>
                            Forgot your password?
                          </a>
                        </div>
                      </div>
                      <div className="row" style={style}>
                        <div className="col-8 col-start-3">
                          {botDetectionEnabled && (
                            <BotDetection
                              siteKey={botDetectionSitekey}
                              onVerify={setToken}
                              onError={setErrorBanner}
                            />
                          )}
                        </div>
                      </div>

                      <div className="login-btn">
                        <Button
                          dataRole="continue-btn"
                          className="btn btn-primary"
                          isLoading={isLoading}
                          isDisabled={isLoading || (botDetectionEnabled && token.trim() === "")}>
                          Continue
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="login-modal-table-row">
                  <div className="login-modal-footer" data-role="login-modal-footer">
                    Don&apos;t have a Twilio SendGrid account?
                    <ExternalLink to={"/"} context={signupContext}>
                      Sign up now!
                    </ExternalLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

const sanitizeUsername = (username: string): string => username.trim();

import React from "react";

interface ErrorAlertProps {
  input: Error | JSX.Element | string;
}

/**
 * ErrorAlert takes in a string, an Error, a React element and correctly displays them at the top of the page.
 * @param input
 * @constructor
 */
const ErrorAlert: React.FC<ErrorAlertProps> = ({ input }: ErrorAlertProps) => {
  if (typeof input === "string") {
    return (
      <div id="login-error-alert-container" className="alert alert-danger" role="alert">
        <div
          style={{
            display: "inline-block",
            fontSize: 16,
            fontFamily: "Times New Roman",
            borderRadius: 30,
            border: "1px solid #b71c1c",
            padding: "4px 10px",
          }}>
          !
        </div>
        {input}
      </div>
    );
  }
  if (input instanceof Error) {
    return (
      <div id="login-error-alert-container" className="alert alert-danger" role="alert">
        <div
          style={{
            display: "inline-block",
            fontSize: 16,
            fontFamily: "Times New Roman",
            borderRadius: 30,
            border: "1px solid #b71c1c",
            padding: "4px 10px",
          }}>
          !
        </div>
        {input.message}
      </div>
    );
  }
  return (
    <>
      <div id="login-error-alert-container" className="alert alert-danger" role="alert">
        <div
          style={{
            display: "inline-block",
            fontSize: 16,
            fontFamily: "Times New Roman",
            borderRadius: 30,
            border: "1px solid #b71c1c",
            padding: "4px 10px",
          }}>
          !
        </div>
        {input}
      </div>
    </>
  );
};

export default ErrorAlert;
